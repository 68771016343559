<template>
  <div class="stars">
    <div class="star"></div>
    <div class="star pink"></div>
    <div class="star blue"></div>
    <div class="star yellow"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    /**
     * 红包雨整体背景
     */
    backgroundImg: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.stars {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -10;
}

.star {
  display: block;
  width: 0.133333rem;
  height: 0.133333rem;
  border-radius: 50%;
  background: #fff;
  top: 2.666667rem;
  left: 6.333333rem;
  position: relative;
  transform-origin: 100% 0;
  animation: star-ani 6s infinite linear;
  transition: all 0.2s;
  box-shadow: 0 0 0.066667rem 0.066667rem rgba(255, 255, 255, 0.3);
  opacity: 0;
  z-index: 2;
}

.star:after {
  content: "";
  display: block;
  top: 0;
  left: 0.053333rem;
  border: 0 solid #fff;
  border-width: 0px 1.2rem 0.026667rem 1.2rem;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.3);
  transform: rotate(-45deg) translate3d(1px, 0.08rem, 0);
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.1);
  transform-origin: 0% 100%;
  animation: shooting-ani 3s infinite ease-out;
}

.pink {
  top: 0.4rem;
  left: 6.266667rem;
  background: #e8989a;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
}

.pink:after {
  border-color: transparent transparent transparent #e8989a;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
}

.blue {
  top: 0.466667rem;
  left: 6.76rem;
  background: cyan;
  animation-delay: 7s;
  -webkit-animation-delay: 7s;
  -moz-animation-delay: 7s;
}

.blue:after {
  /* border-color: transpareanimation-delay: 12s; */
  border-color: transparent transparent transparent cyan;
  -webkit-animation-delay: 7s;
  -moz-animation-delay: 7s;
  animation-delay: 7s;
}

.yellow {
  top: 0.666667rem;
  left: 8.5rem;
  background: #ffcd5c;
  animation-delay: 5.8s;
}

.yellow:after {
  border-color: transparent transparent transparent #ffcd5c;
  animation-delay: 5.8s;
}

@keyframes star-ani {
  0% {
    opacity: 0;
    transform: scale(0) rotate(0) translate3d(0, 0, 0);
    -webkit-transform: scale(0) rotate(0) translate3d(0, 0, 0);
    -moz-transform: scale(0) rotate(0) translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0) translate3d(-4rem, 4rem, 0);
    -webkit-transform: scale(1) rotate(0) translate3d(-4rem, 4rem, 0);
    -moz-transform: scale(1) rotate(0) translate3d(-4rem, 4rem, 0);
  }
}
</style>
