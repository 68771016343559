<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-05-06 11:03:32
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-13 09:04:55
 * @ Description: 结算奖励弹窗
 -->
<template>
  <div class="award">
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div class="contents">
        <!-- 奖品内容 -->
        <div class="prize_box">
          <div class="wrapper" v-for="index in 3" :key="index">
            <div class="left">
              <img src="../../assets/H1.png" alt="" />
            </div>
            <span></span>
            <div class="right">
              <div class="title" :style="{ color: colors }">端午节粽子一盒</div>
              <div class="time">有效期：2021.06.14 - 2021.07.14</div>
            </div>
          </div>
        </div>
        <!-- 奖品提示 -->
        <p class="tip">
          奖品已放入<span @click="junpNext">我的奖励</span>中,记得使用哦~
        </p>
      </div>
      <!-- 按钮 -->
      <p class="btn" :style="btnColor" @click="iknow">我知道了</p>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    /**
     * 按钮颜色
     */
    colors: {
      type: String,
      default: "#2FBC78",
    },
    linkUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    /**
     * 按钮颜色
     */
    btnColor() {
      return `background:linear-gradient(0deg, #ffd39a 0%, #e87d2b 89%);`;
    },
  },
  methods: {
    isShow() {
      this.show = true;
    },
    /**
     * 我知道了
     * *用户点击后向最上层父组件传递事件
     */
    iknow() {
      this.show = false;
      this.$emit("iknow");
    },
    /**
     * 点击我的奖励需要跳转的链接
     */
    junpNext() {
      console.log("跳转");
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .van-popup {
  background: transparent;
}
.contents {
  height: 16.933333rem;
  width: 8.733333rem;
  background: url("../../assets/endcover.png") no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  .tips {
    height: 0.666667rem;
    display: block;
    margin: 0 auto;
    padding-top: 0.666667rem;
  }
  .prize_box {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    overflow-y: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch !important;
    &::-webkit-scrollbar {
      display: none;
    }
    .wrapper {
      display: flex;
      position: relative;
      width: 90%;
      height: 1.973333rem;
      margin: auto;
      margin-bottom: 0.16rem;
      filter: drop-shadow(0.026667rem 0.04rem 0.04rem #999);
    }

    .wrapper > div {
      height: 100%;
    }

    .wrapper span {
      position: absolute;
      left: 32%;
      top: 0.106667rem;
      height: calc(100% - 0.213333rem);
      border-left: 1px dotted #eee;
    }

    .left {
      background: #58a;
      background: radial-gradient(
            circle at top right,
            transparent 0.106667rem,
            #fff 0
          )
          top right,
        radial-gradient(circle at bottom right, transparent 0.106667rem, #fff 0)
          bottom right;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      color: white;
      width: 32%;
      border-radius: 0.066667rem 0 0 0.066667rem;
      display: flex;
      align-items: center;
      img {
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        margin: 0 auto;
      }
    }

    .right {
      background: #58a;
      background: radial-gradient(
            circle at top left,
            transparent 0.106667rem,
            #ffffff 0
          )
          top left,
        radial-gradient(
            circle at bottom left,
            transparent 0.106667rem,
            #ffffff 0
          )
          bottom left;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      width: 68%;
      color: white;
      border-radius: 0 0.066667rem 0.066667rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      .title {
        color: #fe4066;
        font-size: 0.426667rem;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        font-weight: bold;
        text-align: left;
      }
      .time {
        margin-top: 0.133333rem;
        font-size: 0.32rem;
        color: #7c848e;
        font-weight: normal;
        text-align: left;
      }
    }
  }
  .tip {
    font-size: 0.373333rem;
    text-align: left;
    color: #ffffff;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    span {
      color: #fe4066;
    }
  }
}
.btn {
  text-align: center;
  width: 5.8rem;
  height: 1.093333rem;
  line-height: 1.093333rem;
  vertical-align: baseline;
  font-size: 0.48rem;
  background: linear-gradient(0deg, #ffd39a 0%, #e87d2b 89%);
  border-radius: 0.546667rem;
  font-weight: 600;
  color: #ffffff;
  border-radius: 0.533333rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: Georgia, "Times New Roman", Times, serif;
  box-shadow: 0px 0px 0.133333rem rgba(f,f,f, 0.6);
}
</style>
