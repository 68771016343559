const audio = document.createElement("audio");

const playMusicUrl = (url) => {
  audio.src = url;
  audio.loop = false;
  audio.play();
};

const stopBGM = () => {
  audio.pause();
  audio.currentTime = 0;
};

export default {
  playMusicUrl,
  stopBGM,
};
