<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-25 10:34:34
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-13 09:14:04
 * @ Description: 红包
 -->
<template>
  <div
    :class="['packet', pause == true ? 'ispause' : '']"
    id="packet"
    @animationend="animationend"
    :style="style"
  >
    <img
      :class="[isShow == true ? 'animate__animated animate__bounceOut' : '']"
      :src="imgurl == null ? options.imgurl : imgurl"
      alt=""
      :style="size"
      @click="receive"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false, //红包被点击后隐藏
    };
  },
  props: {
    /**
     * !红包配置项目
     * ?包括红包的大小与旋转角度等
     */
    options: {
      type: Object,
    },
    /**
     * !是否暂停红包动画
     */
    pause: {
      type: Boolean,
      default: false,
    },
    /**
     * 红包图片的资源
     */
    packetImg: {
      type: Array,
    },
    /**
     * 生成稀有红包的概率
     */
    rare: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    size() {
      /**
       * 取红包大小随机数
       */
      let size =
        Math.random() * (this.options.maxsize - this.options.minsize) +
        this.options.minsize;
      return `transform: scale(${size});`;
    },
    rotate() {
      /**
       * 取旋转最大与最小值的随机数
       */
      let rotate =
        Math.random() * (this.options.maxrotate - this.options.minrotate) +
        this.options.minsize;
      return rotate;
    },
    style() {
      /**
       * 红包的动画配置
       * *x 红包的x轴坐标
       */
      let x = Math.random() * (90 - 0) + 0;
      let styles = `transform:rotate(${this.rotate}deg);left:${x}%;animation-duration:${this.options.duration}s;animation-timing-function:${this.options.timing};`;
      return styles;
    },
    /**
     * 红包图片
     */
    imgurl() {
      let random = Math.floor(Math.random() * (100 - 1) + 1);
      if (this.packetImg && this.packetImg.length !== 0) {
        if (random <= this.rare) {
          let randomIndex = Math.floor(
            Math.random() * (this.packetImg.length - 1) + 1
          );
          return this.packetImg[randomIndex];
        }
        return this.packetImg[0];
      } else {
        return null;
      }
    },
  },
  methods: {
    receive(e) {
      /**
       * 红包点击后 隐藏红包并显示特效
       */
      this.isShow = true;
      setTimeout(() => {
        this.$emit("receive");
      }, 100);
    },
    /**
     * 监听动画效果结束
     */
    animationend() {
      this.$emit("animationOver");
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes show_packet {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(
      0.133333rem,
      90vh,
      -0.133333rem
    ); /*vh:相对于视口的高度。视口被均分为100单位的vh*/
  }
}
.packet {
  position: absolute;
  top: -10%;
  animation: show_packet 2s linear;
  animation-fill-mode: forwards;
  -webkit-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  img {
    height: 1.6rem;
    display: block;
  }
}
.ispause {
  animation-play-state: paused;
}
</style>
