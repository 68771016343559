<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-27 11:28:49
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-18 09:31:28
 * @ Description: 红包点击连击数
 -->
<template>
  <div class="tips">
    <img
      :src="statusList[randoms]"
      :class="[isShow ? 'animate__animated animate__bounceIn' : 'hide']"
      alt=""
    />
    <!-- <p @click="onJump" :style="{color: colors}">跳过</p> -->
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      isShow: false,
      randoms: 0,
      statusList: [
        require("../../assets/bingo.png"),
        require("../../assets/bad-luck.png"),
        require("../../assets/big.png"),
      ],
    };
  },
  props: {
    colors: {
      type: String,
      default: "#53cb8e",
    },
  },
  methods: {
    setTips(randomIdx) {
      this.isShow = false;
      this.randoms = randomIdx;
      // this.randoms = 0;
      // this.randoms = Math.floor(Math.random() * (4 - 1));
      // console.log('randoms========',this.randoms);
      setTimeout(() => {
        this.isShow = false;
      }, 30);
    },
    onJump() {
      /**
       * !点击跳过之后，页面的动画和生成红包的循环指令将被暂停
       * *点击取消之后动画继续
       * *点击跳过之后结束所有动画
       */
      const _that = this;
      _that.$emit("pause");
      Dialog.alert({
        title: "通知",
        message: "跳过红包雨，会立刻进行奖励结算，是否跳过？",
        showCancelButton: true,
        confirmButtonText: "跳过",
        confirmButtonColor: this.colors,
      })
        .then(() => {
          /**
           * 点击跳过
           */
          _that.$emit("onOver");
        })
        .catch(() => {
          /**
           * 点击取消
           */
          _that.$emit("onCancel");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.tips {
  position: fixed;
  bottom: 0.266667rem;
  left: 0;
  width: 100%;
  .hide {
    opacity: 0;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: right;
    font-size: 0.32rem;
    margin-right: 0.266667rem;
    padding: 0.133333rem 0.266667rem;
    background: rgba(0,0,0, 0.6);
    float: right;
    border-radius: 0.293333rem;
  }
}
</style>
