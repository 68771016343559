<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-27 13:40:22
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-12 17:08:31
 * @ Description: 秒表
 -->
<template>
  <div>
    <div class="second">
      <!-- 如果类型是红包雨类型是1的时候 表明是秒表类的 -->
      <img src="@/assets/second.png" alt="" />
      <div class="time">{{ times }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      times: 0,
      isShow: false,
      isPause: false,
    };
  },
  mounted() {
    this.times = this.time;
    /**
     * 只有红包雨类型为1的时候才显示秒表
     */
    this.countDown();
  },
  props: {
    packetType: {
      type: Number,
      default: 1,
    },
    /**
     * 倒计时时间
     */
    time: {
      type: Number,
      default: 30,
    },
    colors: {
      type: String,
      default: "#2FBC78",
    },
  },
  methods: {
    countDown() {
      if (this.times <= 0) {
        window.cancelAnimationFrame(this.down);
      } else {
        if (this.isPause == true) {
          clearTimeout();
          window.cancelAnimationFrame(this.down);
          return;
        } else {
          setTimeout(() => {
            this.times--;
            this.down = window.requestAnimationFrame(this.countDown);
          }, 1000);
        }
      }
    },
    /**
     * 是否暂停
     */
    onPause() {
      this.isPause = true;
    },
    /**
     * 继续
     */
    continue() {
      this.isPause = false;
      this.countDown();
    },
  },
};
</script>
<style lang="less" scoped>
.second {
  width: 0.8rem;
  height: 0.893333rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .time {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 0.933333rem;
    text-align: center;
    transform: translate(-50%, -50%);
    font-weight: bold;
    transition: all 0.1s;
    font-size: 0.346667rem;
    font-weight: 600;
    color: #f41623;
  }
}
</style>
