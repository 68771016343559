<!--
 * @ Author: yangzhiwei
 * @ Create Time: 2021-05-12 16:37:50
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-12 17:15:38
 * @ Description: 红包计数器
 -->

<template>
  <div class="number">
    <img src="@/assets/tianchong.png" alt="" />
    <p :style="{ color: colors }">
      x<span>{{ haveNumer }}</span>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    /**
     * 获得的奖品数量
     */
    haveNumer: {
      type: Number,
      default: 0,
    },
    colors: {
      type: String,
      default: "#2FBC78",
    },
  },
  watch: {
    haveNumer() {
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
.number {
  display: flex;
  align-items: center;
  img {
    width: 1.066667rem;
    height: 1.066667rem;
    transform: rotate(-25deg);
  }
  p {
    margin: 0;
    padding: 0;
    margin-left: 0.133333rem;
    color: #e29227;
    font-size: 0.64rem;
    span {
      margin-left: 0.133333rem;
    }
  }
}
</style>
