<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-26 16:29:01
 * @ Modified by: mahoushaojo
 * @ Modified time: 2021-05-06 10:22:15
 * @ Description: 水波纹效果
 -->
<template>
  <div
    class="ripple"
    :style="{ top: coord.clientY + 'px', left: coord.clientX + 'px' }"
  >
    <div class="dot"></div>
  </div>
</template>
<script>
export default {
  props: {
    coord: {
      type: Object,
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes small_ripple {
  0% {
    width: 0.4rem;
    height: 0.4rem;
  }
  100% {
    width: 0.933333rem;
    height: 0.933333rem;
    opacity: 0;
  }
}
@keyframes big_ripple {
  0% {
    width: 1.2rem;
    height: 1.2rem;
    display: block;
  }
  100% {
    width: 1.6rem;
    height: 1.6rem;
    opacity: 0;
    z-index: -999;
  }
}
.ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  animation: big_ripple 1s ease-in-out;
  transition: all 0.2s linear;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%);
  box-shadow: inset -0.2rem -0.2rem 0.466667rem rgba(255, 255, 255, 0.3),
    /* 淡灰色渐变背景，光源位于左上方 */ inset 0px 0px 0.066667rem
      rgba(255, 255, 255, 0.3),
    /* 泡沫内部深灰色边界 */ inset 0px 0px 0.333333rem rgba(255, 255, 255, 0.3),
    /* 泡沫内部白色渐变效果 */ inset 0.04rem 0.04rem 0.066667rem
      rgba(255, 255, 255, 0.4),
    /* 颜色稍深的右下边缘阴影效果 */ 0 0 0.333333rem rgba(255, 255, 255, 0.45);
  /* 泡沫周围的白色发光效果，以更好地突显边缘 */
  .dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.4rem;
    height: 0.4rem;
    border: 0.053333rem solid rgba(f,f,f, 0.5);
    border-radius: 50%;
    margin: 0 auto;
    transition: all 0.3s;
    box-shadow: inset -0.2rem -0.2rem 0.466667rem rgba(255, 255, 255, 0.3),
      /* 淡灰色渐变背景，光源位于左上方 */ inset 0px 0px 0.066667rem
        rgba(255, 255, 255, 0.3),
      /* 泡沫内部深灰色边界 */ inset 0px 0px 0.333333rem
        rgba(255, 255, 255, 0.3),
      /* 泡沫内部白色渐变效果 */ inset 0.04rem 0.04rem 0.066667rem
        rgba(255, 255, 255, 0.2),
      /* 颜色稍深的右下边缘阴影效果 */ 0 0 0.333333rem rgba(255, 255, 255, 0.45);
    /* 泡沫周围的白色发光效果，以更好地突显边缘 */
    animation: small_ripple 0.6s ease-in-out;
    transition: all 0.2s linear;
    animation-fill-mode: forwards;
  }
}
</style>
