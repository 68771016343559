<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-27 14:18:00
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-13 08:56:25
 * @ Description: 进度条
 -->
<template>
  <div class="rate_content">
    <p class="title">获得奖励</p>
    <div class="rate">
      <div class="rate_box" v-for="index in overNumber" :key="index">
        <img class="bg" src="../../assets/jlbg_hb.png" alt="" />
        <div class="imgbox">
          <img
            src="../../assets/tianchong.png"
            :class="[
              'tianchong',
              rateNumer >= index ? 'animate__animated animate__rubberBand' : '',
            ]"
            :style="{ display: rateNumer >= index ? 'block' : 'none' }"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    rateNumer: {
      type: Number,
      default: 0,
    },
    overNumber: {
      type: Number,
      default: 3,
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  font-size: 0.32rem;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 0.733333rem;
  margin: 0.133333rem 0.266667rem;
}
.rate {
  display: flex;
  align-items: center;
  .rate_box {
    width: 1.16rem;
    height: 1.066667rem;
    margin-left: 0.266667rem;
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      display: block;
    }
    .imgbox {
      position: absolute;
      width: 90%;
      height: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .tianchong {
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}
</style>
