<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-28 10:12:17
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-12 15:43:23
 * @ Description: 红包雨开始封面图
 -->
<template>
  <div class="cover_box" v-if="isClose == false">
    <!-- 占位底层黑色透明背景 -->
    <div
      class="place"
      :class="[isStart ? 'animate__animated animate__fadeOut' : '']"
    ></div>
    <div class="cover">
      <!-- 关闭按钮 -->
      <img class="close" @click="onClose" src="@/assets/close.png" alt="" />
      <!-- 封面图 -->
      <img
        :class="[
          'cover_img',
          isStart ? 'animate__animated animate__bounceOutUp' : '',
        ]"
        :src="coverImg == '' ? noImg : coverImg"
        alt=""
      />
      <!-- 按钮 -->
      <div class="btn">
        <p
          :style="btnColor"
          :class="[isStart ? 'animate__animated animate__bounceOutDown' : '']"
          @click="onStart"
        >
          开始抢红包
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isClose: false,
      isStart: false,
      noImg: require("@/assets/cover.png"),
    };
  },
  props: {
    /**
     * 按钮颜色
     */
    colors: {
      type: String,
      default: "#2FBC78",
    },
    /**
     * 封面图
     */
    coverImg: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["liveGame"]),
    /**
     * 按钮颜色
     */
    btnColor() {
      return `background:linear-gradient(0deg, #ffd39a 0%, #e87d2b 89%);`;
    },
  },
  mounted() {
    if (this.liveGame.status == 1) {
      this.onStart();
    }
    this.$Bus.$on("startGame", () => {
      // console.log('开始----------');
      this.onStart();
      // this.isStart = true;
      // setTimeout(() => {
      //   this.$emit("onStart");
      // }, 800);
    });
  },
  methods: {
    async onStart() {
      this.$store.dispatch('liveGame/getLiveGame',this.liveGame.id).then(res=>{
        if (this.liveGame.status == 0) {
          this.$toast("游戏未开始!");
          return;
        }
        this.isStart = true;
        setTimeout(() => {
          this.$emit("onStart");
        }, 800);
      })
    },
    /**
     * 用户点击关闭
     */
    onClose() {
      this.isClose = true;
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="less" scoped>
.cover_box {
  position: fixed;
  z-index: 800;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.place {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.cover {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close {
    height: 0.453333rem;
    width: 0.453333rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  .cover_img {
    width: 7.693333rem;
    //height: 11.226667rem;
    display: block;
    margin: 0 auto;
  }
  .btn {
    position: absolute;
    bottom: 0.266667rem;
    left: 50%;
    transform: translateX(-50%);
    p {
      font-weight: bold;
      text-align: center;
      font-size: 0.48rem;
      color: rgba(f,f,f,1);
      font-family: Georgia, "Times New Roman", Times, serif;
      margin: 0 auto;
      width: 5.8rem;
      height: 1.093333rem;
      line-height: 1.093333rem;
      background: linear-gradient(0deg, #ffd39a 0%, #e87d2b 89%);
      border-radius: 0.546667rem;
      box-shadow: 0px 0px 0.133333rem rgba(f,f,f, 0.6);
      &:active {
        opacity: 0.8;
      }
    }
  }
}
</style>
