<template>
  <RedPacket
    :cover-img="coverImg"
    :background-img="backgroundImg"
    :packet-type="packetType"
    :packet-number="packetNumber"
    :packetImg="packetImg"
    :live-game-user-id="liveGameUserId"
  ></RedPacket>
</template>
<script>
import RedPacket from "@/components/RedPacket";
export default {
  components: {
    RedPacket,
  },
  mounted() {
    this.liveGameUserId = this.$route.query.liveGameUserId;
    console.log("活动用户 id:", this.liveGameUserId);
  },
  data() {
    return {
      liveGameUserId: "",
      packetNumber: 3000,
      backgroundImg: require("@/assets/images/bgImg/bg_hongbao.png"),
      coverImg: require("@/assets/images/hongbao101.png"),
      packetType: 1,
      packetImg: [
        require("@/assets/H1.png"),
        require("@/assets/timg.png"),
        require("@/assets/icons/icon_gift.png"),
        require("@/assets/H4.png"),
        require("@/assets/images/redRain.png"),
      ],
    };
  },
};
</script>
