<!--
 * @ Author: mahoushaojo
 * @ Create Time: 2021-04-28 10:52:10
 * @ Modified by: yangzhiwei
 * @ Modified time: 2021-05-12 15:53:13
 * @ Description: 抢红包准备阶段倒计时
 -->
<template>
  <div class="content">
    <div class="prepare" :style="{ color: colors }">
      <div class="tips">
        <img src="../../assets/title.png" alt="" />
      </div>
      <img
        :class="['time', isShow ? 'animate__animated animate__flipInY' : '']"
        :src="imgList[time - 1]"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: 3,
      isShow: true,
      timeout: null,
      imgList: [
        require("../../assets/one.png"),
        require("../../assets/two.png"),
        require("../../assets/three.png"),
      ],
    };
  },
  props: {
    colors: {
      type: String,
      default: "#2FBC78",
    },
  },
  mounted() {
    this.$emit("onReady");
    // this.$nextTick(() => {
    //   this.timeout = setInterval(() => {
    //     if (this.time > 1) {
    //       this.isShow = false;
    //       setTimeout(() => {
    //         this.isShow = true;
    //         this.time--;
    //       }, 100);
    //     } else {
    //       /**
    //        * 倒计时结束
    //        * *准备就绪
    //        */
    //       this.$emit("onReady");
    //       clearInterval(this.timeout);
    //     }
    //   }, 900);
    // });
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background: rgba($color: #000000, $alpha: 0.6);
  background-color: rgba(0, 0, 0, 0.6);
}
.prepare {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  .tips {
    img {
      width: 6.693333rem;
      height: 1.173333rem;
      display: block;
      margin: 0 auto;
    }
  }
  .time {
    width: 0.973333rem;
    height: 1.12rem;
    display: block;
    margin: 0 auto;
    margin-top: 0.8rem;
  }
}
</style>
