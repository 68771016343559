import { render, staticRenderFns } from "./packet.vue?vue&type=template&id=11909c9b&scoped=true"
import script from "./packet.vue?vue&type=script&lang=js"
export * from "./packet.vue?vue&type=script&lang=js"
import style0 from "./packet.vue?vue&type=style&index=0&id=11909c9b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11909c9b",
  null
  
)

export default component.exports